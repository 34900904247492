import React, { useState } from 'react'

import { Button, Link } from '@atoms'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@atoms/DropdownMenu'
import AppErrorsAlert from '@molecules/Alerts/AppErrorsAlert'
import InfoCard from '@molecules/InfoCard'
import NotificationLink from '@molecules/NotificationLink'
import { Slot } from '@hooks/useSlots'
import { scopedTranslation } from '@utils/I18n'

import GroupCriteria from '../list-groups/GroupCriteria'
import PointPeople from '../list-groups/PointPeople'
import LeaderNames from '../list-groups/LeaderNames'
import GroupAddress from '../list-groups/GroupAddress'
import type { GroupsShowType, SharedInertiaProps, RouteProps } from '../../pages/GroupShow'
import GroupDeleteDialog from './GroupDeleteDialog'
import MailerSenderDialog from './MailerSenderDialog'
import useSupporterFilteringStore from '../filter-supporters/stores/useSupporterFilteringStore'
import { ExportSupportersButton } from '../export-supporters/ExportSupportersButton'

const t = scopedTranslation('components')

type GroupCardType = Pick<GroupsShowType, 'group'> & {
  globalMailerSender: string
}

export default function GroupCard({
  globalMailerSender,
  group,
  permissions,
  routes,
}: GroupCardType & SharedInertiaProps & RouteProps) {
  const { canEditGroup, canSyncGroup, emailInboxEnabled, pdfExportEnabled, canDestroyGroup, canEditMailerSender } =
    permissions
  const {
    id,
    name,
    status,
    publicUrl,
    description,
    pointPeople,
    notifyAllAdmins,
    streetAddress,
    city,
    postcode,
    state,
    captains,
    criteria,
  } = group
  const activeFilters = useSupporterFilteringStore((state) => state.filters)
  const nameSearch = useSupporterFilteringStore((state) => state.supporterName)
  const sortBy = useSupporterFilteringStore((state) => state.currentSort)

  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [mailerSenderModalOpen, setMailerSenderModalOpen] = useState(false)

  return (
    <>
      <AppErrorsAlert errorsPath="showGroupCardErrors" />
      <InfoCard dataCompId={'group-details-card'} data-testid={`group-card-${name}`}>
        <Slot name="heading">
          <h1 className="tw-text-gray-900 tw-font-semibold tw-text-2xl">{name}</h1>

          <p data-testid="group-description" className="tw-text-gray-900">
            {description}
          </p>

          <GroupAddress streetAddress={streetAddress} city={city} postcode={postcode} state={state} />
        </Slot>

        <Slot name="main">
          <div className="tw-flex tw-flex-col tw-gap-5">
            <GroupCriteria criteria={criteria} />

            <div className="tw-flex tw-flex-col tw-gap-3">
              <PointPeople
                groupName={name}
                notifyAllAdmins={Boolean(notifyAllAdmins)}
                pointPersonEmails={pointPeople.nodes.map((pp) => pp.email)}
              />

              <LeaderNames leaderType="captains" leaders={captains.nodes} />
            </div>
          </div>
        </Slot>

        <Slot name="actions">
          <Slot name="primary">
            {emailInboxEnabled && (
              <NotificationLink
                ariaLabel={t('group_card.aria_labels.email_inbox', {
                  name,
                  count: group.emailThreads.totalCount,
                })}
                href={routes.emailInboxRoute}
                leadingNotification={group.emailThreads.totalCount.toString()}
              >
                {t('group_card.buttons.email_inbox')}
              </NotificationLink>
            )}
            <NotificationLink
              ariaLabel={t('group_card.aria_labels.sms_inbox', {
                name,
                count: group.smsThreads.totalCount,
              })}
              href={routes.smsInboxRoute}
              leadingNotification={group.smsThreads.totalCount.toString()}
            >
              {t('group_card.buttons.sms_inbox')}
            </NotificationLink>
            <NotificationLink
              ariaLabel={t('group_card.aria_labels.group_events', {
                name,
                count: group.events.totalCount,
              })}
              href={routes.groupEventsRoute}
              leadingNotification={group.events.totalCount.toString()}
            >
              {t('group_card.buttons.group_events')}
            </NotificationLink>
          </Slot>
          <Slot name="secondary">
            <div className="tw-flex tw-flex-wrap tw-gap-2">
              {canEditGroup && (
                <>
                  <Link
                    size="sm"
                    rank="link"
                    leadingIcon="pencil-02"
                    ariaLabel={t('group_card.aria_labels.edit_group', { name })}
                    href={`/groups/${id}/edit?ref=${window.location.pathname + window.location.search}`}
                  >
                    {t('group_card.buttons.edit_group')}
                  </Link>
                  <Link
                    size="sm"
                    rank="link"
                    leadingIcon="pencil-02"
                    ariaLabel={t('group_card.aria_labels.edit_group_criteria', { name })}
                    href={`/groups/${id}/criteria/edit?ref=${window.location.pathname + window.location.search}`}
                  >
                    {t('group_card.buttons.edit_group_criteria')}
                  </Link>
                  <Link
                    size="sm"
                    rank="link"
                    leadingIcon="users-01"
                    ariaLabel={t('group_card.aria_labels.manage_captains', {
                      name,
                    })}
                    href={`/groups/${id}/captains?ref=${window.location.pathname + window.location.search}`}
                  >
                    {t('group_card.buttons.manage_captains')}
                  </Link>
                </>
              )}

              <Link
                size="sm"
                rank="link"
                leadingIcon="phone-call-01"
                ariaLabel={t('group_card.aria_labels.call_lists', { name })}
                href={routes.callListsRoute}
                dataCompId={'group-details-card_call-lists'}
              >
                {t('group_card.buttons.call_lists')}
              </Link>

              <Link
                size="sm"
                rank="link"
                leadingIcon="line-chart-up"
                ariaLabel={t('group_card.aria_labels.analytics', { name })}
                href={routes.analyticsRoute}
                dataCompId={'group-details-card_analytics'}
              >
                {t('group_card.buttons.analytics')}
              </Link>

              <Link
                disabled={status === 'hidden' || !publicUrl}
                href={publicUrl ?? ''}
                leadingIcon="eye"
                size="sm"
                rank="link"
                ariaLabel={t('group_card.aria_labels.public_page', { name })}
                dataCompId={'group-details-card_view-public-page'}
              >
                {t('group_card.buttons.public_page')}
              </Link>

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    rank="tertiary"
                    size="sm"
                    trailingIcon={'chevron-down'}
                    ariaLabel={t('group_card.aria_labels.more_actions', { name })}
                  >
                    {t('group_card.buttons.more_actions')}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="tw-flex tw-flex-col tw-gap-1 tw-px-1 tw-py-1">
                  <DropdownMenuItem asChild>
                    <Link
                      size="sm"
                      rank="link"
                      leadingIcon="funnel"
                      ariaLabel={t('group_card.aria_labels.advanced_filters', { name })}
                      href={routes.advancedFiltersRoute}
                      dataCompId={'group-details-card_advanced_filters'}
                    >
                      {t('group_card.buttons.advanced_filters')}
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem asChild>
                    <Link
                      size="sm"
                      rank="link"
                      leadingIcon="user-x"
                      ariaLabel={t('group_card.aria_labels.supporter_contact_preferences', { name })}
                      href={routes.optOutsRoute}
                      dataCompId={'group-details-card_supporter_contact_preferences'}
                    >
                      {t('group_card.buttons.supporter_contact_preferences')}
                    </Link>
                  </DropdownMenuItem>

                  {pdfExportEnabled && (
                    <DropdownMenuItem asChild>
                      <ExportSupportersButton
                        asChild
                        exportRequestParams={{
                          exportFormat: 'pdf',
                          groupId: group.id,
                          filters: activeFilters,
                          nameSearch,
                          sortBy,
                        }}
                      >
                        <Button
                          size="sm"
                          rank="link"
                          leadingIcon="download-cloud"
                          dataCompId={'group-details-card_export-pdf'}
                          ariaLabel={t('group_card.aria_labels.export_pdf', { name })}
                        >
                          {t('group_card.buttons.export_pdf')}
                        </Button>
                      </ExportSupportersButton>
                    </DropdownMenuItem>
                  )}

                  {canEditMailerSender && (
                    <DropdownMenuItem asChild>
                      <Button
                        size="sm"
                        rank="link"
                        leadingIcon="mail-01"
                        ariaLabel={t('group_card.aria_labels.set_group_sender_email', { name })}
                        dataCompId={'group-details-mailer_sender'}
                        onClick={() => {
                          setMailerSenderModalOpen(true)
                        }}
                      >
                        {t('group_card.buttons.set_group_sender_email')}
                      </Button>
                    </DropdownMenuItem>
                  )}

                  {canSyncGroup && (
                    <DropdownMenuItem asChild>
                      <Link
                        size="sm"
                        rank="link"
                        leadingIcon="refresh"
                        href={`/groups/${id}/sync`}
                        dataCompId={'group-details-card_sync-supporters'}
                      >
                        {t('group_card.buttons.sync')}
                      </Link>
                    </DropdownMenuItem>
                  )}

                  {canDestroyGroup && (
                    <DropdownMenuItem asChild>
                      <Button
                        size="sm"
                        rank="link"
                        color="danger"
                        leadingIcon="trash-01"
                        onClick={() => {
                          setOpenConfirmation(true)
                        }}
                        ariaLabel={t('group_card.aria_labels.archive_group', { name })}
                      >
                        {t('group_card.buttons.archive_group')}
                      </Button>
                    </DropdownMenuItem>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>

              <GroupDeleteDialog open={openConfirmation} onOpenChange={setOpenConfirmation} group={group} />
              <MailerSenderDialog
                open={mailerSenderModalOpen}
                onOpenChange={setMailerSenderModalOpen}
                groupId={id}
                mailerSender={
                  group.mailerSender && Boolean(group.mailerSender) ? group.mailerSender : globalMailerSender
                }
              />
            </div>
          </Slot>
        </Slot>
      </InfoCard>
    </>
  )
}
