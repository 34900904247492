import React from 'react'

import { scopedTranslation } from '@utils/I18n'
import { formatTime } from '@utils/formatTime'

import { Shift } from '../shared/types'

import { EditShiftDialog } from '../edit_shifts/EditShiftDialog'
import { DeleteShiftDialog } from '../delete_shifts/DeleteShiftDialog'

const t = scopedTranslation('attributes.shift')

type ShiftCardProps = {
  shift: Shift
  onShiftUpdate: () => void
}

export const ShiftCard: React.FC<ShiftCardProps> = ({ shift, onShiftUpdate }) => {
  const shiftStartTime = formatTime(shift.startTime)
  const shiftEndTime = formatTime(shift.endTime)

  return (
    <div className="tw-flex">
      <div className="tw-text-black">
        <p>
          {shiftStartTime} - {shiftEndTime}
        </p>
        <p>
          {t('role')}: {shift.roleName}
        </p>
      </div>
      <div className="tw-flex tw-space-x-2">
        <EditShiftDialog shift={shift} onShiftUpdate={onShiftUpdate} />
        <DeleteShiftDialog id={shift.id} onShiftDeleted={onShiftUpdate} />
      </div>
    </div>
  )
}
