import React from 'react';
import dayjs from 'dayjs';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useFormWithErrors from '@frontend/hooks/useFormWithErrors';

import { Dialog, Button } from '@atoms';
import { scopedTranslation, i18nValidation } from '@utils/I18n'
import Icon from '@components/utils/Icon'

import { EditShiftFieldRow } from './EditShiftFieldRow'
import { useEditShiftMutation } from './useEditShiftMutation'
import { Shift } from '../shared/types'
import { formatTime24hours } from '@utils/formatTime'

const t = scopedTranslation('components.edit_shift')
const tAttributes = scopedTranslation('attributes.shift')

type EditShiftDialogProps = {
  shift: Shift
  onShiftUpdate: () => void
}

export const EditShiftDialog: React.FC<EditShiftDialogProps> = ({ shift, onShiftUpdate }) => {
  const [open, setOpen] = React.useState(false)

  const shiftSchema = yup.object({
    startTime: yup.string().label(tAttributes('start_time')).required(i18nValidation('required')),
    endTime: yup.string().label(tAttributes('end_time')).required(i18nValidation('required')),
    roleName: yup.string().label(tAttributes('role')).required(i18nValidation('required')),
  })

  const onSuccess = React.useCallback(() => {
    setOpen(false)
    onShiftUpdate()
  }, [onShiftUpdate])

  const {
    register,
    handleSubmit,
    formState: { errors },
    setErrors: setFormErrors,
  } = useFormWithErrors({
    resolver: yupResolver(shiftSchema),
    mode: 'onTouched',
    defaultValues: {
      startTime: formatTime24hours(shift.startTime),
      endTime: formatTime24hours(shift.endTime),
      roleName: shift.roleName,
    },
  })

  const { editShift } = useEditShiftMutation()

  const onSubmit = async (data: { startTime: string; endTime: string; roleName: string }) => {
    const convertToDateTimeString = (timeString: string, originalDate: Date): string => {
      return dayjs(originalDate)
        .set('hour', parseInt(timeString.split(':')[0], 10))
        .set('minute', parseInt(timeString.split(':')[1], 10))
        .toISOString()
    }

    const shiftStartTime = convertToDateTimeString(data.startTime, shift.startTime)
    const shiftEndTime = convertToDateTimeString(data.endTime, shift.endTime)

    await editShift(shift.id, shiftStartTime, shiftEndTime, data.roleName, onSuccess, setFormErrors)
  }

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild={true}>
        <Button rank="link" ariaLabel={t('edit_shift')}>
          <Icon type="pencil-02" />
        </Button>
      </Dialog.Trigger>
      <Dialog.Content size="3xl">
        <Dialog.Header>
          <Dialog.Title>{t('edit_shift')}</Dialog.Title>
        </Dialog.Header>
        <form onSubmit={handleSubmit(onSubmit)} className="tw-flex tw-flex-col tw-gap-4">
          <EditShiftFieldRow register={register} errors={errors} />
          <Dialog.Footer>
            <Dialog.Close> {t('cancel')} </Dialog.Close>
            <Button type="submit" trailingIcon="arrow-circle-broken-right">
              {t('save_shift')}
            </Button>
          </Dialog.Footer>
        </form>
      </Dialog.Content>
    </Dialog.Root>
  )
}
