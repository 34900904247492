import React from 'react';
import dayjs from 'dayjs';

import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import useFormWithErrors from '@frontend/hooks/useFormWithErrors';

import { Dialog, Button } from '@atoms';
import { scopedTranslation, i18nValidation } from '@utils/I18n'
import { formatTime24hours } from '@utils/formatTime'

import { AddShiftFieldRow } from './AddShiftFieldRow'
import { useAddShiftMutation } from './useAddShiftMutation'
import { Event } from '../shared/types'

type AddShiftDialogProps = {
  event: Event
  onShiftUpdate: () => void
}

const t = scopedTranslation('components.add_shift')
const tAttributes = scopedTranslation('attributes.shift')
const tShiftsIndex = scopedTranslation('shifts_index')

const shiftSchema = yup.object({
  startTime: yup.string().label(tAttributes('start_time')).required(i18nValidation('required')),
  endTime: yup.string().label(tAttributes('end_time')).required(i18nValidation('required')),
  roleName: yup.string().label(tAttributes('role')).required(i18nValidation('required')),
  volunteersRequiredCount: yup.number().typeError(tAttributes('validation.volunteers_required')),
})

export const AddShiftDialog: React.FC<AddShiftDialogProps> = ({ event, onShiftUpdate }) => {
  const { id: eventId, startTime: eventStartTime, endTime: eventEndTime } = event

  const [open, setOpen] = React.useState(false)

  const onSuccess = React.useCallback(() => {
    setOpen(false)
    onShiftUpdate()
  }, [onShiftUpdate])

  const {
    register,
    handleSubmit,
    formState: { errors },
    setErrors: setFormErrors,
  } = useFormWithErrors({
    resolver: yupResolver(shiftSchema),
    mode: 'onSubmit',
    defaultValues: {
      startTime: formatTime24hours(eventStartTime),
      endTime: formatTime24hours(eventEndTime),
      volunteersRequiredCount: 1,
      roleName: '',
    },
  })

  const { addShift } = useAddShiftMutation()

  const onSubmit = async (data: {
    startTime: string
    endTime: string
    roleName: string
    volunteersRequiredCount: number
  }) => {
    const convertToDateTimeString = (timeString: string, originalDate: Date): string => {
      return dayjs(originalDate)
        .set('hour', parseInt(timeString.split(':')[0], 10))
        .set('minute', parseInt(timeString.split(':')[1], 10))
        .toISOString()
    }

    const shiftStartTime = convertToDateTimeString(data.startTime, eventStartTime)
    const shiftEndTime = convertToDateTimeString(data.endTime, eventEndTime)

    await addShift(
      eventId,
      shiftStartTime,
      shiftEndTime,
      data.roleName,
      data.volunteersRequiredCount,
      onSuccess,
      setFormErrors
    )
  }

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild={true}>
        <Button>{tShiftsIndex('add_shift')}</Button>
      </Dialog.Trigger>
      <Dialog.Content size="3xl">
        <Dialog.Header>
          <Dialog.Title>{t('new_shift')}</Dialog.Title>
        </Dialog.Header>
        <form onSubmit={handleSubmit(onSubmit)} className="tw-flex tw-flex-col tw-gap-4">
          <AddShiftFieldRow register={register} errors={errors} />
          <Dialog.Footer>
            <Dialog.Close> {t('cancel')} </Dialog.Close>
            <Button type="submit" trailingIcon="arrow-circle-broken-right">
              {t('submit_shift')}
            </Button>
          </Dialog.Footer>
        </form>
      </Dialog.Content>
    </Dialog.Root>
  )
}
