import useMutation from '@hooks/useGQLMutation';
import { useErrorsStore, type ErrorType } from '@stores/errorsStore';
import { serverErrorsToFormErrors } from '@frontend/adapters/serverErrorsAdapter';

import { addShiftMutation } from './mutations';

export const useAddShiftMutation = () => {
  const { setErrors, clearErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }));

  const { mutate } = useMutation(addShiftMutation);

  const addShift = async (
    eventId: number,
    startTime: string,
    endTime: string,
    roleName: string,
    volunteersRequiredCount: number,
    onSuccess: () => void,
    setFormErrors: (errors: any) => void
  ) => {
    clearErrors('addShift');
    mutate(
      {
        input: {
          eventId,
          shift: {
            startTime,
            endTime,
            roleName,
            draft: true,
          },
          volunteersRequiredCount,
        }
      },
      {
        onSuccess: (response) => {
          if (response?.addShift?.roster) {
            onSuccess();
          } else {
            handleErrors(
              response?.addShift?.errors as ErrorType[] || [{ message: 'An unknown error occurred', code: 'unknown' }],
              setFormErrors
            );
          }
        },
        onError: () => {
          handleErrors([{ message: 'An unknown error occurred', code: 'unknown' }], setFormErrors);
        },
      }
    );
  };

  const handleErrors = (errors: ErrorType[], setFormErrors: (errors: any) => void) => {
    setErrors({ errorsPath: 'addShift', errors });
    setFormErrors({
      errorsBasePath: 'shifts',
      errors,
      adapter: serverErrorsToFormErrors,
    });
  };

  return { addShift };
};
