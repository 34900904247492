import React from 'react';
import usePartialReload from '@hooks/usePartialReload';

import { scopedTranslation } from '@utils/I18n';
import { Table } from '@components/molecules/Table';

import { ShiftCard } from './ShiftCard';
import { AddShiftDialog } from '../add_shifts/AddShiftDialog';
import { Event, Shift } from '../shared/types';

const t = scopedTranslation('shifts_index')

const sortShifts = (a: Shift, b: Shift): number => {
  const aStartTime = new Date(a.startTime).getTime()
  const bStartTime = new Date(b.startTime).getTime()
  const aEndTime = new Date(a.endTime).getTime()
  const bEndTime = new Date(b.endTime).getTime()

  if (aStartTime !== bStartTime) {
    return aStartTime - bStartTime
  }

  if (aEndTime !== bEndTime) {
    return aEndTime - bEndTime
  }

  return a.roleName.localeCompare(b.roleName)
}

export const ShiftsTable: React.FC<{ event: Event }> = ({ event }) => {
  const handleShiftUpdate = () => {
    usePartialReload('', ['event'], () => {})
  }

  const shifts = event.roster?.shifts?.nodes || []

  const dateString = new Date(event.startTime).toLocaleDateString()

  return (
    <Table.Root>
      <Table.Header>
        <Table.Row>
          <Table.Cell>
            {dateString} {t('shifts')}
          </Table.Cell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {[...shifts].sort(sortShifts).map((shift) => (
          <Table.Row key={shift.id}>
            <Table.Cell>
              <ShiftCard shift={shift} onShiftUpdate={handleShiftUpdate} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.Cell>
            <AddShiftDialog event={event} onShiftUpdate={handleShiftUpdate} />
          </Table.Cell>
        </Table.Row>
      </Table.Footer>
    </Table.Root>
  )
}
