import React from 'react'

import { ShiftsTable } from '../features/shifts/ShiftsTable';

const ShiftsIndex = ({ event }) => {

  return (
    <div className='tw-p-4'>
      <ShiftsTable
        event={event}
      />
    </div>
  )
}

export default ShiftsIndex;
